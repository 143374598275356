(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/pix-product-type-score.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/pix-product-type-score.js');

'use strict';

const {
  PixProductBase
} = svs.components.sport.pixHandler.productTypes;
class PixProductTypeScore extends PixProductBase {}
setGlobal('svs.components.sport.pixHandler.productTypes.PixProductTypeScore', PixProductTypeScore);

 })(window);