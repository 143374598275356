(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/common/cache.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/common/cache.js');
"use strict";


const {
  LOGGER_NAME,
  PIX_CACHE_TIME,
  STORAGE_DOMAIN_KEY
} = svs.components.sport.pixHandler.common.constants;
const logger = new svs.core.log.Logger(LOGGER_NAME);
const StorageStatus = Object.seal({
  hasBeenTested: false,
  hasPassedTests: false
});

function _testAvailability() {
  if (!StorageStatus.hasBeenTested) {
    StorageStatus.hasBeenTested = true;
    StorageStatus.hasPassedTests = false;
    try {
      const testKey = 'testsvsstorage';
      sessionStorage.setItem(testKey, testKey);
      sessionStorage.removeItem(testKey);
      StorageStatus.hasPassedTests = true;
    } catch (error) {
      logger.info('Write-test in sessionStorage failed - disabling cache');
    }
  }
  return StorageStatus.hasPassedTests;
}

async function _cleanupDomainAsync() {
  try {
    if (_testAvailability()) {
      const allItemKeys = Object.keys(sessionStorage).filter(key => key.startsWith("".concat(STORAGE_DOMAIN_KEY, ".")));
      await Promise.all(allItemKeys.map(async key => {
        const json = sessionStorage.getItem(key);
        if (!json) {
          return;
        }
        const {
          expireTS
        } = JSON.parse(json) || {};
        if (expireTS && Date.now() > expireTS) {
          sessionStorage.removeItem(key);
        }
      }));
    }
  } catch (error) {
    logger.info("Failed to cleanup items in sessionStorage (\"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
  }
}
_cleanupDomainAsync();

function unsetData(_ref) {
  let {
    itemKey
  } = _ref;
  try {
    if (_testAvailability()) {
      const key = "".concat(STORAGE_DOMAIN_KEY, ".").concat(itemKey);
      sessionStorage.removeItem(key);
    }
  } catch (error) {
    logger.info("Failed to remove item in sessionStorage (\"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
  }
}

function getData(_ref2) {
  let {
    itemKey
  } = _ref2;
  try {
    if (_testAvailability()) {
      const key = "".concat(STORAGE_DOMAIN_KEY, ".").concat(itemKey);
      const json = sessionStorage.getItem(key);
      if (!json) {
        return null;
      }
      const {
        expireTS,
        data
      } = JSON.parse(json) || {};
      if (expireTS && Date.now() > expireTS) {
        unsetData({
          itemKey
        });
      } else {
        return data;
      }
    }
  } catch (error) {
    logger.info("Failed to read item in sessionStorage (\"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
  }
  return null;
}

function setData(_ref3) {
  let {
    itemKey,
    data,
    ttl
  } = _ref3;
  try {
    if (_testAvailability()) {
      const key = "".concat(STORAGE_DOMAIN_KEY, ".").concat(itemKey);
      const json = JSON.stringify({
        expireTS: Date.now() + (ttl || PIX_CACHE_TIME),
        data
      });
      sessionStorage.setItem(key, json);
    }
  } catch (error) {
    logger.info("Failed to save item in sessionStorage (\"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
  }
}
function reset() {
  StorageStatus.hasBeenTested = false;
}

setGlobal('svs.components.sport.pixHandler.common.cache', {
  getData,
  setData,
  unsetData,
  reset
});

 })(window);