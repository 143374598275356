(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/products/pix-product-challenge.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/products/pix-product-challenge.js');

'use strict';

const {
  PixProductType1x2
} = svs.components.sport.pixHandler.productTypes;
const {
  getSpecificDrawName
} = svs.utils.products;
class PixProductChallenge extends PixProductType1x2 {
  getURL() {
    const productName = getSpecificDrawName(this.productId);
    const numberOfRows = this.getNumberOfRows(this.rowPrice);
    return "/wager/1/".concat(productName, "/pix?flavour=SafeChallenge&systemType=").concat(this.systemType, "&systemNumberMapping=").concat(numberOfRows, "&drawNumber=").concat(this.drawNumber);
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.products.PixProductChallenge', PixProductChallenge);

 })(window);