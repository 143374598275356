(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/prescript.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/prescript.js');

'use strict';

svs = svs || {};
svs.components = svs.components || {};
svs.components.sport = svs.components.sport || {};
svs.components.sport.pixHandler = svs.components.sport.pixHandler || {};


 })(window);