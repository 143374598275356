(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/products/pix-product-bomben.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/products/pix-product-bomben.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  PixProductTypeScore
} = svs.components.sport.pixHandler.productTypes;
const {
  getSpecificDrawName
} = svs.utils.products;
const {
  SAFE_BOMBEN_MATCHEN
} = svs.components.sport.pixHandler.common.constants.FLAVOUR_TYPES;
const _getOutcomeType = outcome => {
  const isHomeWin = outcome.home > outcome.away;
  const isDraw = outcome.home === outcome.away;
  const isAwayWin = outcome.home < outcome.away;
  return {
    isHomeWin,
    isDraw,
    isAwayWin
  };
};
const _convertBombenBoardToOutputData = boards => {
  const newBoards = [];
  boards.forEach(_ref => {
    let {
      outcomes
    } = _ref;
    const newOutcomes = {
      homeWin: [],
      draw: [],
      awayWin: []
    };
    outcomes.forEach(outcome => {
      const outcomeType = _getOutcomeType(outcome);
      if (outcomeType.isHomeWin) {
        newOutcomes.homeWin.push([outcome.home.toString(), outcome.away.toString()]);
      }
      if (outcomeType.isDraw) {
        newOutcomes.draw.push([outcome.home.toString(), outcome.away.toString()]);
      }
      if (outcomeType.isAwayWin) {
        newOutcomes.awayWin.push([outcome.home.toString(), outcome.away.toString()]);
      }
    });
    newBoards.push(newOutcomes);
  });
  return newBoards;
};
const _convertBombenBoardToWager = boards => {
  const newBoards = [];
  boards.forEach(_ref2 => {
    let {
      outcomes
    } = _ref2;
    const newOutcomes = [];
    outcomes.forEach(outcome => {
      newOutcomes.push([outcome.home.toString(), outcome.away.toString()]);
    });
    newBoards.push(newOutcomes);
  });
  return newBoards;
};
var _flavour = new WeakMap();
class PixProductBomben extends PixProductTypeScore {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _flavour, SAFE_BOMBEN_MATCHEN);
  }
  get flavour() {
    return _classPrivateFieldGet(_flavour, this);
  }
  getURL() {
    return "/wager/1/".concat(getSpecificDrawName(this.productId), "/pix?flavour=").concat(_classPrivateFieldGet(_flavour, this), "&size=").concat(this.systemCost, "&drawNumber=").concat(this.drawNumber);
  }
  signs() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (bomben)');
    }
    return _convertBombenBoardToOutputData(data);
  }
  board() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (bomben)');
    }
    return {
      bomben: _convertBombenBoardToWager(data)
    };
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.products.PixProductBomben', PixProductBomben);

 })(window);