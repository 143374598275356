(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/products/pix-product-racing.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/products/pix-product-racing.js');

'use strict';

const {
  PixProductBase
} = svs.components.sport.pixHandler.productTypes;
const {
  logger
} = svs.components.sport.pixHandler.client;
const ROW_PRICE_HIGH_FIVE = 20;
const _convertRacingPixToSigns = _ref => {
  let {
    positionedParticipants,
    reserve
  } = _ref;
  const _turnListIntoSigns = list => list.map(item => item.participantNumber).sort();
  return [...Object.values(positionedParticipants).map(_turnListIntoSigns), _turnListIntoSigns([reserve])];
};
const _convertRacingPixToWagerBoard = _ref2 => {
  let {
    positionedParticipants,
    reserve,
    numRows
  } = _ref2;
  const replacement = reserve.participantNumber;
  let extras = [];
  let rxRaces = Object.values(positionedParticipants).map(list => list.map(item => item.participantNumber));
  if (numRows === 1) {
    extras = rxRaces.map(list => list[0]);
    rxRaces = [[], [], [], [], []];
  }
  return {
    boardProps: ['REPLACEMENT', 'WEIGHTEDQP'],
    systemType: numRows === 1 ? ['RX_POS'] : ['RX_MATHPOS'],
    systemNum: 1,
    extras,
    rxRaces,
    replacement
  };
};
class PixProductRacing extends PixProductBase {
  getURL() {
    return "/racing/3/xpert/picks?drawNumber=".concat(this.drawNumber, "&rows=").concat(this.getNumberOfRows(this.rowPrice));
  }
  signs() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      positionedParticipants,
      reserve
    } = data[0] || {};
    if (!positionedParticipants || typeof positionedParticipants !== 'object' || Object.values(positionedParticipants).some(item => !Array.isArray(item))) {
      throw new Error('Missing "pix.positionedParticipants" in response from wager-API (racing)');
    }
    if (!(reserve !== null && reserve !== void 0 && reserve.participantNumber) || typeof reserve.participantNumber !== 'number') {
      throw new Error('Invalid "pix.reserve" in response from wager-API (racing)');
    }
    if (Object.keys(positionedParticipants).some((key, index) => parseInt(key, 10) !== index + 1)) {
      logger.info('Warning: Unexpected structure of "pix.positionedParticipants" delivered by wager-API (racing). This might lead to wrong behavior.');
    }
    return _convertRacingPixToSigns({
      positionedParticipants,
      reserve
    });
  }
  board() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      positionedParticipants,
      reserve
    } = data[0] || {};
    if (!positionedParticipants || typeof positionedParticipants !== 'object' || Object.values(positionedParticipants).some(item => !Array.isArray(item))) {
      throw new Error('Missing "pix.positionedParticipants" in response from wager-API (racing)');
    }
    if (!(reserve !== null && reserve !== void 0 && reserve.participantNumber) || typeof reserve.participantNumber !== 'number') {
      throw new Error('Invalid "pix.reserve" in response from wager-API (racing)');
    }
    if (Object.keys(positionedParticipants).some((key, index) => parseInt(key, 10) !== index + 1)) {
      logger.info('Warning: Unexpected structure of "pix.positionedParticipants" delivered by wager-API (racing). This might lead to wrong behavior.');
    }
    const numRows = Math.floor(this.systemCost / this.rowPrice);
    return {
      racing: {
        rowPriceMulti: ROW_PRICE_HIGH_FIVE,
        numRows,
        rxBoards: [_convertRacingPixToWagerBoard({
          positionedParticipants,
          reserve,
          numRows
        })]
      }
    };
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.products.PixProductRacing', PixProductRacing);

 })(window);