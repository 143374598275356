(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/products/pix-product-fulltraff.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/products/pix-product-fulltraff.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  PixProductTypeScore
} = svs.components.sport.pixHandler.productTypes;
const {
  getSpecificDrawName
} = svs.utils.products;
const {
  SAFE_FULLTRAFF
} = svs.components.sport.pixHandler.common.constants.FLAVOUR_TYPES;
const FULLTRAFF_LENGTH_OF_SIGNS = 8;
const _convertFulltraffPixEventsToSigns = events => events.map(_ref => {
  let {
    outcomes
  } = _ref;
  const signsRow = new Array(FULLTRAFF_LENGTH_OF_SIGNS).fill(false);
  outcomes.forEach(_ref2 => {
    let {
      value
    } = _ref2;
    signsRow[value] = true;
  });
  return signsRow;
});
const _convertFulltraffPixEventsToWagerBoard = events => events.map(_ref3 => {
  let {
    outcomes
  } = _ref3;
  return outcomes.map(_ref4 => {
    let {
      value
    } = _ref4;
    return String(value);
  });
});
var _flavour = new WeakMap();
class PixProductFulltraff extends PixProductTypeScore {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _flavour, SAFE_FULLTRAFF);
  }
  get flavour() {
    return _classPrivateFieldGet(_flavour, this);
  }
  getURL() {
    return "/wager/1/".concat(getSpecificDrawName(this.productId), "/pix?flavour=").concat(_classPrivateFieldGet(_flavour, this), "&systemNumberMapping=").concat(this.systemCost, "&drawNumber=").concat(this.drawNumber, "&systemType=").concat(this.systemType);
  }
  signs() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (fullträff)');
    }
    return _convertFulltraffPixEventsToSigns(data);
  }
  board() {
    let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    const {
      outcomes
    } = data[0] || {};
    if (!Array.isArray(outcomes)) {
      throw new Error('Missing "pix.outcomes" in response from wager-API (fullträff)');
    }
    return {
      fulltraff: _convertFulltraffPixEventsToWagerBoard(data)
    };
  }
}
setGlobal('svs.components.sport.pixHandler.productTypes.products.PixProductFulltraff', PixProductFulltraff);

 })(window);