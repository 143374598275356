(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/product-types/pix-product-base.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/product-types/pix-product-base.js');

'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  SYSTEM_TYPES,
  FLAVOUR_TYPES
} = svs.components.sport.pixHandler.common.constants;
var _productId = new WeakMap();
var _drawNumber = new WeakMap();
var _systemType = new WeakMap();
var _flavour = new WeakMap();
var _rowPrice = new WeakMap();
var _systemCost = new WeakMap();
var _branding = new WeakMap();
var _productSizes = new WeakMap();
class PixProductBase {
  constructor() {
    _classPrivateFieldInitSpec(this, _productId, void 0);
    _classPrivateFieldInitSpec(this, _drawNumber, void 0);
    _classPrivateFieldInitSpec(this, _systemType, SYSTEM_TYPES.MSYS);
    _classPrivateFieldInitSpec(this, _flavour, FLAVOUR_TYPES.SAFE_1X2);
    _classPrivateFieldInitSpec(this, _rowPrice, 1);
    _classPrivateFieldInitSpec(this, _systemCost, void 0);
    _classPrivateFieldInitSpec(this, _branding, void 0);
    _classPrivateFieldInitSpec(this, _productSizes, void 0);
  }
  get drawNumber() {
    return _classPrivateFieldGet(_drawNumber, this);
  }
  setDrawNumber(drawNumber) {
    _classPrivateFieldSet(_drawNumber, this, drawNumber);
    return this;
  }
  get productId() {
    return _classPrivateFieldGet(_productId, this);
  }
  setProductId(productId) {
    _classPrivateFieldSet(_productId, this, productId);
    return this;
  }
  get rowPrice() {
    return _classPrivateFieldGet(_rowPrice, this);
  }
  setRowPrice(rowPrice) {
    _classPrivateFieldSet(_rowPrice, this, rowPrice);
    return this;
  }
  get systemType() {
    return _classPrivateFieldGet(_systemType, this);
  }
  setSystemType(systemType) {
    _classPrivateFieldSet(_systemType, this, systemType);
  }
  get systemCost() {
    return _classPrivateFieldGet(_systemCost, this);
  }
  setSystemCost(systemCost) {
    _classPrivateFieldSet(_systemCost, this, systemCost);
  }
  get flavour() {
    return _classPrivateFieldGet(_flavour, this);
  }
  setFlavour(flavour) {
    _classPrivateFieldSet(_flavour, this, flavour);
  }
  getNumberOfRows(rowPrice) {
    return this.systemCost / (rowPrice || 1);
  }
  get branding() {
    return _classPrivateFieldGet(_branding, this);
  }
  setBranding(branding) {
    _classPrivateFieldSet(_branding, this, branding);
  }
  get productSizes() {
    return _classPrivateFieldGet(_productSizes, this);
  }
  setProductSizes(productSizes) {
    _classPrivateFieldSet(_productSizes, this, productSizes);
  }
  getURL() {}
  signs() {}
  board() {}
}
setGlobal('svs.components.sport.pixHandler.productTypes.PixProductBase', PixProductBase);

 })(window);