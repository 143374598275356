(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/pix-handler.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/pix-handler.js');

'use strict';

const createPixHandler = svs.components.sport.pixHandler.pix.createPixHandler;
const instances = [];
const getPixHandler = _ref => {
  let {
    isPixMix
  } = _ref;
  if (!instances.length || !instances.find(instance => instance.isPixMix === isPixMix)) {
    instances.push(createPixHandler({
      isPixMix
    }));
  }
  return instances.find(instance => instance.isPixMix === isPixMix);
};
setGlobal('svs.components.sport.pixHandler', {
  getPixHandler
});

 })(window);