(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/common/logger.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/common/logger.js');
'use strict';

const {
  LOGGER_NAME
} = svs.components.sport.pixHandler.common.constants;
const {
  log
} = svs.core;
const logger = log.getLogger(LOGGER_NAME);
setGlobal('svs.components.sport.pixHandler.client.logger', logger);

 })(window);