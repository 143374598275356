(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/pix-handler/pix/create-pix-data.js') >= 0) return;  svs.modules.push('/components/sport/pix-handler/pix/create-pix-data.js');

'use strict';

function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  jupiterFetch
} = svs.core;
const {
  cache
} = svs.components.sport.pixHandler.common;
const {
  createPixDataKey
} = svs.components.sport.pixHandler.common.helpers;
var _cache = new WeakMap();
var _cachedPixData = new WeakMap();
class PixData {
  constructor(_ref) {
    let {
      productId,
      drawNumber,
      systemCost,
      systemType,
      additionalCacheKey,
      shouldGenerateNewPix,
      url: _url
    } = _ref;
    _classPrivateFieldInitSpec(this, _cache, cache);
    _classPrivateFieldInitSpec(this, _cachedPixData, {});
    _defineProperty(this, "fetch", async url => {
      try {
        const response = await jupiterFetch.fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json'
          }
        });
        const data = await response.json();
        if (!response.ok) {
          var _data$error;
          throw new Error("Error when trying to fetch pix: ".concat(data === null || data === void 0 || (_data$error = data.error) === null || _data$error === void 0 ? void 0 : _data$error.message));
        }
        return data;
      } catch (error) {
        throw new Error("Error when trying to fetch pix: ".concat(error));
      }
    });
    return (async () => {
      let pixData;
      const cacheKey = createPixDataKey(productId, drawNumber, systemCost, systemType, additionalCacheKey);
      pixData = _classPrivateFieldGet(_cache, this).getData({
        itemKey: cacheKey
      });
      if (!shouldGenerateNewPix && pixData) {
        _classPrivateFieldSet(_cachedPixData, this, pixData.events || pixData);
        return this;
      }
      const data = await this.fetch(_url);
      if ((!pixData || shouldGenerateNewPix) && (data !== null && data !== void 0 && data.pix || data !== null && data !== void 0 && data.pixes || data !== null && data !== void 0 && data.picks)) {
        pixData = data.pix || data.pixes || data.picks;
        _classPrivateFieldGet(_cache, this).setData({
          itemKey: cacheKey,
          data: pixData
        });
      }
      _classPrivateFieldSet(_cachedPixData, this, pixData.events || pixData);
      return this;
    })();
  }
  get cachedPixData() {
    return _classPrivateFieldGet(_cachedPixData, this);
  }
}
setGlobal('svs.components.sport.pixHandler.pix.PixData', PixData);

 })(window);